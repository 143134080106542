<template>
  <div class="w-screen h-screen">
    <!-- <nav-bar /> -->
    <div
      class="flex flex-col p-8 mx-auto mt-2 border-2 rounded-lg shadow-2xl SummaryCard xl:w-4/5"
      v-if="this.dataIsLoaded"
    >
      <h3>{{ content.individualView.title }}</h3>
      <div class="FirstColumn">
        <div
          v-if="dataIsLoaded"
          class="PersonInfo flex flex-col justify-center items-start bg-white rounded-xl mt-2"
        >
          <h4>
            {{ this.selectedUserInfo.User.name.charAt(0).toUpperCase() + this.selectedUserInfo.User.name.slice(1) }} ({{ this.age == null ? 'no data': this.age }})
          </h4>
          <p class="text-lg">
            <b>{{ content.individualView.personalInformation.text1 }}:</b> <span>{{ selectedUserInfo.User.levelOfEducation == null ? 'no data' : this.education[selectedUserInfo.User.levelOfEducation] }}</span>
          </p>
          <p class="text-lg">
            <b>{{ content.individualView.personalInformation.text3 }}:</b> <span>{{ this.selectedUserInfo.User.culturalOrigin == null ? 'no data' :this.selectedUserInfo.User.culturalOrigin }}</span>
          </p>
        </div>
        <div class="SelectPerson">
          <select
            class="w-64 bg-white border-2 border-black border-solid select"
            v-model="selectedUser"
          >
            <option
              :value="user"
              v-for="user in teamRatingUsers"
              :key="user"
            >
              {{ user }}
            </option>
          </select>
        </div>
      </div>

      <hr
        style="
          width: 100%;
          height: 0px;
          border: none;
          border-top: 1px solid black;
        "
      >

      <div class="SecondColumn">
        <div class="p-8 bg-white shadow-xl RadarChart">
          <h3 v-if="!isTraitsSelected">
            {{ content.individualView.spiderChartTitle }}
          </h3>
          <h3 v-if="isTraitsSelected">
            Traits
          </h3>
          <spiderChart
            :input-data="computedChartData"
            :lables="true"
            :input-lables="[
              'Simple Attention',
              'Attention Control',
              'Short-term Memory',
              'Working Memory',
              'Impulse Control',
              'Creativity & Cognitive Flexibility',
              'Conceptualization',
              'Strategic Thinking'
            ]"
            v-if="dataIsLoaded && !isTraitsSelected"
            :key="selectedUser+'chart'"
          />
          <spiderChart
            :input-data="chartDataTraits"
            :lables="true"
            :input-lables="labelTraitsFull"
            v-if="dataIsLoaded && isTraitsSelected"
            :key="selectedUser+'chartTraits'"
          />
        </div>
        <div
          v-if="!isTraitsSelected"
          class="
            Summary
            bg-white
            flex flex-col
            align-center
            justify-start
            gap-2
            shadow-xl
            p-4
          "
        >
          <div><h3>{{ content.individualView.summary.title }}</h3></div>
          <div
            class="flex flex-col justify-start gap-2 self-rating"
            style="height: 50%"
          >
            <p
              class="
                p-1
                mt-1
                w-28
                rounded-xl
                font-bold
                shadow-md
                flex
                justify-center
                items-center
                text-sm 2xl:text-lg
              "
              style="background-color: #d9d9d9"
            >
              {{ content.individualView.summary.selfRatingInfo.title }}
            </p>
            <p class="ml-1 font-bold text-sm 2xl:text-lg">
              {{ content.individualView.summary.selfRatingInfo.text1 }}
              :
            </p>
            <!-- self rating most underrated -->
            <p class="ml-1 text-sm 2xl:text-lg">
              {{ getDiffLabels('mUnder') }}
              <span
                class="w-4 p-1 font-bold shadow-md rounded-xl"
                style="background-color: #d9d9d9"
              >{{ Summary.selfRating.mUnder.value }}%</span>
            </p>
            <p class="ml-1 font-bold text-sm 2xl:text-lg">
              {{ content.individualView.summary.selfRatingInfo.text2 }}
              :
            </p>
            <!-- self rating most overrated -->
            <p class="ml-1 text-sm 2xl:text-lg">
              {{ getDiffLabels('mOver') }}
              <span
                class="w-4 p-1 font-bold shadow-md rounded-xl"
                style="background-color: #d9d9d9"
              >{{ Summary.selfRating.mOver.value }}%</span>
            </p>
            <!-- self rating spot on -->
            <p class="ml-1 font-bold text-sm 2xl:text-lg">
              {{ content.individualView.summary.selfRatingInfo.text3 }}
              :
            </p>
            <p class="ml-1 text-sm 2xl:text-lg">
              {{ Summary.selfRating.Spot.label != 'Nothing' ? summaryContent[Summary.selfRating.Spot.label].label: 'Nothing' }}
            </p>
          </div>
          <div
            class="flex flex-col justify-start gap-2 team-rating"
            style="height: 50%"
          >
            <p
              class="
                p-1
                mt-1
                w-32
                rounded-xl
                font-bold
                shadow-md
                flex
                justify-center
                items-center
                text-sm 2xl:text-lg
              "
              style="background-color: #d9d9d9"
            >
              {{ content.individualView.summary.teamRatingInfo.title }}
            </p>
            <p class="ml-1 font-bold text-sm 2xl:text-lg">
              {{ content.individualView.summary.teamRatingInfo.text1 }}
              :
            </p>
            <p class="ml-1 text-sm 2xl:text-lg">
              {{ Summary.teamRating.mUnder.label == 'no data' ? 'no data' : summaryContent[Summary.teamRating.mUnder.label].label }}
              <span
                class="w-4 p-1 font-bold shadow-md rounded-xl"
                style="background-color: #d9d9d9"
              >{{ Summary.teamRating.mUnder.label == 'no data ' ? '' : Summary.teamRating.mUnder.value }}%</span>
              <span
                class="w-4 p-1 ml-1 font-bold shadow-md rounded-xl"
                style="background-color: #d9d9d9"
                v-if="this.Summary.teamRating.mUnder.value <= -20"
              >Hidden Talent</span>
            </p>
            <p class="ml-1 font-bold text-sm 2xl:text-lg">
              {{ content.individualView.summary.teamRatingInfo.text2 }}
              :
            </p>
            <p class="ml-1 text-sm 2xl:text-lg">
              {{ Summary.teamRating.mOver.label == 'no data' ? 'no data' : summaryContent[Summary.teamRating.mOver.label].label }}
              <span
                class="w-4 p-1 font-bold shadow-md rounded-xl"
                style="background-color: #d9d9d9"
              >{{ Summary.teamRating.mOver.value }}%</span>
            </p>
            <p class="ml-1 font-bold text-sm 2xl:text-lg">
              {{ content.individualView.summary.teamRatingInfo.text3 }}
              :
            </p>
            <p class="ml-1 text-sm 2xl:text-lg">
              {{ Summary.teamRating.Spot.label != 'Nothing' ? summaryContent[Summary.teamRating.Spot.label].label:'Nothing' }}
            </p>
          </div>
        </div>
        <!-- traits summary -->
        <div
          v-if="isTraitsSelected"
          class="
            Summary
            bg-white
            flex flex-col
            align-center
            justify-center
            gap-4
            shadow-xl
            p-4
          "
        >
          <div><h3>{{ content.individualView.summary.title }}</h3></div>
          <div
            class="flex flex-col justify-start gap-2 self-rating"
            style="height: 50%"
          >
            <p
              class="
                p-1
                mt-1
                w-48
                rounded-xl
                font-bold
                shadow-md
                flex
                justify-center
                items-center
                text-sm 2xl:text-lg
              "
              style="background-color: #d9d9d9"
            >
              Traits Difference
            </p>
            <p class="ml-1 font-bold text-sm 2xl:text-lg">
              {{ content.individualView.summary.selfRatingInfo.text1 }}
              :
            </p>
            <!-- self rating most underrated -->
            <p class="ml-1 text-sm 2xl:text-lg">
              {{ Summary.traitsRating.mUnder.label }}
              <span
                class="w-4 p-1 font-bold shadow-md rounded-xl"
                style="background-color: #d9d9d9"
              >{{ Summary.traitsRating.mUnder.value }}%</span>
            </p>
            <p class="ml-1 font-bold text-sm 2xl:text-lg">
              {{ content.individualView.summary.selfRatingInfo.text2 }}
              :
            </p>
            <!-- self rating most overrated -->
            <p class="ml-1 text-sm 2xl:text-lg">
              {{ Summary.traitsRating.mOver.label }}
              <span
                class="w-4 p-1 font-bold shadow-md rounded-xl"
                style="background-color: #d9d9d9"
              >{{ Summary.traitsRating.mOver.value }}%</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import reportContent from '../../../content/reportContent.json'
import content from '../content.json'
import spiderChart from './spiderChart.vue'
import { gitests as testAppContent } from '@/content/testAppContent.json'

export default {
  components: { spiderChart },
  props: {
    campaignId: {
      type: String,
      required: false,
      default: null
    },
    isTraitsSelected: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  data () {
    return {
      dataIsLoaded: false,
      content: content,
      age: null,
      education: testAppContent.inputs.values.education,
      chartData: [{
        name: 'Capacity',
        data: []
      },
      {
        name: 'Self Rating',
        data: []
      },
      {
        name: 'Team Rating',
        data: []
      }],
      chartDataTraits: [{
        name: 'self rating',
        data: []
      },
      {
        name: 'average team rating',
        data: []
      }],
      summaryContent: null,
      resultsData: null,
      teamRatingUsers: null,
      selectedUser: '',
      usersInfo: {},
      selectedUserInfo: {},
      Summary: {
        selfRating: null,
        teamRating: null,
        traitsRating: null
      },
      labelTraitsFull: [
        'Leadership',
        'Target Orientation',
        'Adaptability',
        'Teambuilding',
        'Communication',
        'Commitment',
        'Accountability',
        'Service Minded',
        'Independence',
        'Accuracy',
        'Initiative',
        'Cooperativeness',
        'Ambition',
        'Problem Solving',
        'Stress Resistance'
      ],
      labels: [
        'Simple Attention',
        'Attention Control',
        'Short-term Memory',
        'Working Memory',
        'Impulse Control',
        'Creativity & Cognitive Flexibility',
        'Conceptualization',
        'Strategic Thinking'
      ]
    }
  },
  computed: {
    computedChartData () {
      return this.chartData
    }
  },
  methods: {
    formatDate (date) {
      return date.slice(0, date.indexOf('T'))
    },
    getAge (dateString) {
      var ageInMilliseconds = new Date() - new Date(dateString)
      return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365)
    },
    getDiffLabels (measure) {
      let output = ''
      if (this.Summary.selfRating[measure].label === 'no data') {
        output = 'no data'
      } else {
        this.Summary.selfRating[measure].labels.forEach(l => {
          output += `${this.summaryContent[l].label}, `
        })
      }
      return output
    }

  },
  async mounted () {
    const [resultsData, usersInfo] = await this.$api.getTeamRatingResultsForGraphs(this.campaignId)
    this.summaryContent = reportContent.Charts.MainMeasures

    this.resultsData = resultsData[1]
    this.teamRatingUsers = resultsData[2]
    this.usersInfo = usersInfo

    this.selectedUser = this.teamRatingUsers[0]

    this.chartData[0].data = this.resultsData.individualInfo[this.selectedUser].capacity
    this.chartData[1].data = this.resultsData.individualInfo[this.selectedUser].selfRatingCognitiveMeasuresFormatted
    this.chartData[2].data = this.resultsData.individualInfo[this.selectedUser].teamRatingCognitiveMeasuresFormatted

    this.chartDataTraits[0].data = this.resultsData.individualInfo[this.selectedUser].selfRatingTraitsFormatted
    this.chartDataTraits[1].data = this.resultsData.individualInfo[this.selectedUser].teamRatingTraitsFormatted

    this.selectedUserInfo = this.usersInfo.find(item => item.User.name === this.selectedUser)
    this.Summary.selfRating = this.resultsData.individualInfo[this.selectedUser].diffData.self
    this.Summary.teamRating = this.resultsData.individualInfo[this.selectedUser].diffData.team
    this.Summary.traitsRating = this.resultsData.individualInfo[this.selectedUser].diffData.traits

    this.dataIsLoaded = true
  },
  watch: {
    selectedUser () {
      this.chartData[0].data = this.resultsData.individualInfo[this.selectedUser].capacity
      this.chartData[1].data = this.resultsData.individualInfo[this.selectedUser].selfRatingCognitiveMeasuresFormatted
      this.chartData[2].data = this.resultsData.individualInfo[this.selectedUser].teamRatingCognitiveMeasuresFormatted

      this.chartDataTraits[0].data = this.resultsData.individualInfo[this.selectedUser].selfRatingTraitsFormatted
      this.chartDataTraits[1].data = this.resultsData.individualInfo[this.selectedUser].teamRatingTraitsFormatted

      this.selectedUserInfo = this.usersInfo.find(item => item.User.name === this.selectedUser)
      const date = this.selectedUserInfo.User.dateOfBirth == null ? 'no data' : this.formatDate(this.selectedUserInfo.User.dateOfBirth)
      this.Summary.selfRating = this.resultsData.individualInfo[this.selectedUser].diffData.self
      this.Summary.teamRating = this.resultsData.individualInfo[this.selectedUser].diffData.team
      this.age = this.selectedUserInfo.User.dateOfBirth == null ? 'no data' : this.getAge(date)
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 1000px) {
  .SummaryCard {
  height: 712px;
  width: 1400px;
  border-radius: 10px;
}

.SelectPerson {
  margin-right: 200px;
}

.self-rating {
  border-top: rgb(36, 35, 35) solid 1px;
}

.team-rating {
  border-top: rgb(36, 35, 35) solid 1px;
}
.container {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PersonInfo {
  max-width: 500px;
}

.FirstColumn {
  max-height: 170px;
  min-height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.SecondColumn {
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  height: 510px;
  width: 100%;
}
.RadarChart {
  border-radius: 20px;
  width: 700px;
}

.Summary {
  border-radius: 20px;
  width: 700px;
  }
}
@media screen and (min-width: 1700px) {
  .SummaryCard {
    height: 920px;
    width: 1700px;
    border-radius: 10px;
  }
  .FirstColumn {
    max-height: 100%;
    min-height: 170px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .SecondColumn {
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    height: 100%;
    width: 100%;
  }
}

</style>
