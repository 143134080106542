<template>
  <div>
    <radar-chart
      :chart-data="ChartData"
      :options="spiderOptions"
    />
    <!-- <h6 class="font-bold">
      Most overrated: {{ RatingQuestions.cognitive_measures[summary.mOver.label].EN }}, {{ summary.mOver.value }}%
    </h6>
    <h6 class="font-bold">
      Most underrated: {{ RatingQuestions.cognitive_measures[summary.mUnder.label].EN }}, {{ summary.mUnder.value }}%
    </h6> -->
  </div>
</template>

<script>
import { RadarChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import RatingQuestions from '../RatingQuestions.json'
const normColor = 'rgba(162, 209, 248, 1)'
const candidateColor = 'rgba(0, 0, 0, 1)'

const bnormColor = 'rgba(162, 209, 248, 0.2)'
const bcandidateColor = 'rgba(0, 0, 0, 0.2)'

Chart.register(...registerables)
export default {
  components: {
    RadarChart
  },
  props: {
    inputData: {
      type: Array,
      required: true
    },
    lables: {
      type: Array,
      required: true
    },
    summary: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      RatingQuestions,
      // label: this.summary.mOver.label,
      spiderOptions: {
        responsive: true,
        scales: {
          r: {
            max: 9,
            beginAtZero: true,
            ticks: {
              z: 1,
              font: {
                size: 14
              }
            },
            pointLabels: {
              font: {
                size: 14,
                bold: true
              },
              color: 'black'
              // callback: label => {
              //   if (label.includes('&')) {
              //     const split = label.split('&')
              //     split[0] = split[0] + ' &'
              //     return split
              //   }
              //   return label
              // }
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            item: {
              marker: {
                padding: 16
              }
            },
            labels: {
              font: {
                size: 12
              }
            }
          }
        }
      },
      ChartData: {
        labels: [
        ],
        datasets: [
        ]
      },
      colors: {
        colors: [
          normColor,
          candidateColor

        ],
        backgroundColors: [
          bnormColor,
          bcandidateColor
        ]
      }
    }
  },

  methods: {
    updateData () {
      this.ChartData.labels = this.lables
      for (let i = 0; i < this.inputData.length; i++) {
        let tempLabel = ''
        if (this.inputData[i].name === undefined) {
          tempLabel = this.inputData[i].alt_id
        } else {
          tempLabel = this.inputData[i].name
        }
        this.ChartData.datasets.push({
          label: tempLabel,
          data: this.inputData[i].data,
          fill: false,
          backgroundColor: this.colors.colors[i],
          borderColor: this.colors.colors[i],
          pointBackgroundColor: this.colors.colors[i],
          pointBorderColor: this.colors.colors[i],
          pointHoverBackgroundColor: this.colors.backgroundColors[i],
          pointHoverBorderColor: this.colors.backgroundColors[i]
        })
      }
      // if (this.summary === 'asd') {
      //   for (let i = 0; i < this.ChartData.labels.length; i++) {
      //     if (RatingQuestions.cognitive_measures[this.summary.mOver.label].EN === this.ChartData.labels[i]) {
      //       this.ChartData.labels[i] = this.ChartData.labels[i] + ' (+' + this.summary.mOver.value + '%)'
      //     }
      //     if (RatingQuestions.cognitive_measures[this.summary.mUnder.label].EN === this.ChartData.labels[i]) {
      //       this.ChartData.labels[i] = this.ChartData.labels[i] + ' (' + this.summary.mUnder.value + '%)'
      //     }
      //   }
      // }
    }
  },
  mounted () {
    this.updateData()
  }
}
</script>

<style scoped>

</style>
