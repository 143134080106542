<template>
  <div>
    <bar-chart
      :chart-data="datacollection"
      :options="BarOptions"
    />
  </div>
</template>

<script>
import { BarChart } from 'vue-chart-3'

export default {
  components: {
    BarChart
  },
  props: {
    inputData: {
      type: Array,
      required: true
    },
    inputLables: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      datacollection: {
        labels: [],
        datasets: []
      },
      BarOptions: {
        plugins: {
          gridLines: {
            display: false
          },
          legend: {
            display: true,
            position: 'bottom'
          }
        },

        responsive: true,
        maintainAspectRatio: true,
        height: 200
      }
    }
  },
  mounted () {
    this.datacollection.datasets = this.inputData
    this.datacollection.labels = this.inputLables
    this.datacollection.datasets[0].datalabels = {
      display: true,
      align: 'end',
      anchor: 'end',
      backgroundColor: '#d9d9d9',
      font: {
        size: 16
      },
      formatter: (rating, ctx) => {
        const index = ctx.dataIndex
        const cap = this.datacollection.datasets[1].data[index]
        if ((cap - rating) > 2) {
          return 'hidden talent'
        } else {
          return null
        }
      }
    }
  }

  // const ratingArr = this.datacollection.datasets[0]
  // const capArr = this.datacollection.datasets[1]
  // this.datacollection.datasets.push({
  //   data: [ratingArr[0], capArr[0]],
  //   borderColor: 'rgb(0,0,0)',
  //   type: 'line',
  //   pointRadius: 0,
  //   datalabels: {
  //     display: false
  //   },
  //   order: 0
  // })
}

</script>

<style>

</style>
