<template>
  <div>
    <radar-chart
      :chart-data="ChartData"
      :options="options"
    />
  </div>
</template>

<script>
import { RadarChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)
export default {
  components: {
    RadarChart
  },
  props: {
    inputData: {
      type: Array,
      required: true
    },
    lables: {
      type: Boolean,
      required: true
    },
    inputLables: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      options: {
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 9
          }
        },
        plugins: {
          legend: {
            display: this.lables,
            position: 'bottom'
          }
        }
      },
      ChartData: {
        // TODO: lägg labels i en jsonfil som kan användas för alla componenter
        labels: [],
        datasets: [

        ]
      },
      colors: {
        colors: [
          'rgb(133, 105, 241)',
          'rgb(182, 242, 0)',
          'rgb(10, 207, 66)',
          'rgb(217, 11, 49)',
          'rgb(100, 50, 200)'
        ],
        backgroundColors: [
          'rgba(133, 105, 241 ,0.2)',
          'rgba(182, 242, 0 ,0.2)',
          'rgba(10, 207, 66 ,0.2)',
          'rgba(217, 11, 49 ,0.2)',
          'rgba(100, 50, 200 ,0.2)'
        ]
      }
    }
  },

  methods: {
    updateData () {
      this.ChartData.labels = this.inputLables
      for (let i = 0; i < this.inputData.length; i++) {
        let tempLabel = ''
        if (this.inputData[i].name === undefined) {
          tempLabel = this.inputData[i].alt_id
        } else {
          tempLabel = this.inputData[i].name
        }
        this.ChartData.datasets.push({
          label: tempLabel,
          data: this.inputData[i].data,
          fill: true,
          backgroundColor: this.colors.backgroundColors[i],
          borderColor: this.colors.colors[i],
          pointBackgroundColor: this.colors.colors[i],
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: this.colors.colors[i]
        })
      }
    }
  },
  mounted () {
    this.updateData()
  }
}
</script>

<style></style>
