<template>
  <div>
    <div
      class="flex flex-row justify-center mt-6"
    >
      <button
        v-if="cognitiveDataExists"
        :class="traits ? 'bg-black text-white w-24 h-14 rounded-md': 'hover:bg-gray-400 bg-gray-200 text-black w-24 h-14 rounded-md' "
        @click="traits = true"
      >
        Traits
      </button>
      <button
        v-if="cognitiveDataExists"
        :class="!traits ? 'bg-black text-white w-24 h-14 rounded-md': 'hover:bg-gray-400 bg-gray-200 text-black w-24 h-14 rounded-md' "
        @click="traits = false"
      >
        Cognitive
      </button>
      <h1
        v-if="!cognitiveDataExists"
        class="font-bold"
      >
        Only Traits Data
      </h1>
    </div>
    <teamResults
      :campaign-id="campaignId"
      :is-traits-selected="traits"
      @cognitiveDataNotExists="cognitiveDataNotExists()"
    />
    <individualResults
      :campaign-id="campaignId"
      :is-traits-selected="traits"
    />
  </div>
</template>

<script>
import teamResults from '../components/teamResults.vue'
import individualResults from '../components/individualResults.vue'

export default {
  components: {
    teamResults,
    individualResults
  },
  props: {
    campaignId: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      traits: false,
      cognitiveDataExists: true
    }
  },
  methods: {
    cognitiveDataNotExists () {
      this.traits = true
      this.cognitiveDataExists = false
    }
  }

}
</script>
