<template>
  <div class="mb-12">
    <div
      class="flex flex-col p-8 mx-auto shadow-xl border-2 SummaryCard"
      v-if="this.dataIsLoaded"
    >
      <div class="FirstColumn">
        <div class="title">
          <h3>{{ content.teamView.title }}</h3>
          <hr
            style="
            width: 50%;
            height: 0px;
            border: none;
            border-top: 1px solid black;
          "
          >
          <h4
            class="mt-2"
          >
            {{ content.teamView.subtitle }}
          </h4>
        </div>
      </div>
      <div class="SecondColumn">
        <div class="p-8 shadow-xl RadarChart">
          <h3 v-if="!isTraitsSelected">
            {{ content.teamView.spiderChartTitle }}
          </h3>
          <h3 v-if="isTraitsSelected">
            Average traits rating
          </h3>
          <h5 v-if="!isTraitsSelected">
            {{ content.teamView.spiderChartSubtitle }}
          </h5>
          <spiderChartTeam
            :input-data="teamSpiderChartData"
            :lables="Abilities"
            :summary="Summary"
            v-if="dataIsLoaded && !isTraitsSelected"
            :key="'teamChart1'"
          />
          <spiderChartTeam
            :input-data="teamSpiderChartDataTraits"
            :lables="labelTraitsFull"
            v-if="dataIsLoaded && isTraitsSelected"
            :key="'teamChartTraits1'"
          />
        </div>
        <div
          class="p-2 shadow-xl BarChart"
          v-if="!isTraitsSelected"
        >
          <h3>{{ content.teamView.barChartTitle }}</h3>
          <h5>{{ content.teamView.barChartSubtitle }}</h5>
          <div class="flex flex-row items-center justify-between mt-1 mb-1">
            <select
              v-model="selectedAbility"
              class="w-64 border-2 border-black border-solid select"
            >
              <option disabled>
                Select Ability
              </option>
              <option
                :value="Abilities[index]"
                v-for="(ability, index) in Abilities"
                :key="ability + index"
              >
                {{ ability }}
              </option>
            </select>
            <button
              class="w-16 border-2 border-black rounded-lg"
              @click="bar++"
              v-if="numberOfGroups > 1"
            >
              next
            </button>
          </div>
          <!-- <bar-chart
            v-for="n in numberOfGroups"
            :key="this.selectedAbility + n"
            :input-data="[this.teamRatingBarChartData['teamRatingBarChartData' + n], this.capacityBarChartData['capacityBarChartData' + n]]"
            :input-lables="this.names['names'+n]"
            :v-if="bar == n-1"
          /> -->
          <bar-chart
            :input-data="[
              this.teamRatingBarChartData.teamRatingBarChartData1,
              this.capacityBarChartData.capacityBarChartData1
            ]"
            :input-lables="this.names.names1"
            :key="this.selectedAbility"
            v-if="bar == 0"
          />
          <bar-chart
            :input-data="[
              this.teamRatingBarChartData.teamRatingBarChartData2,
              this.capacityBarChartData.capacityBarChartData2
            ]"
            :input-lables="this.names.names2"
            :key="this.selectedAbility + '2'"
            v-if="bar == 1"
          />
          <bar-chart
            :input-data="[
              this.teamRatingBarChartData.teamRatingBarChartData3,
              this.capacityBarChartData.capacityBarChartData3
            ]"
            :input-lables="this.names.names3"
            :key="this.selectedAbility + '3'"
            v-if="bar == 2"
          />
          <bar-chart
            :input-data="[
              this.teamRatingBarChartData.teamRatingBarChartData4,
              this.capacityBarChartData.capacityBarChartData4
            ]"
            :input-lables="this.names.names4"
            :key="this.selectedAbility + '4'"
            v-if="bar == 3"
          />
        </div>
        <!-- traits summary -->
        <div
          v-if="isTraitsSelected"
          class="
            Summary
            bg-white
            flex flex-col
            align-center
            justify-center
            gap-4
            shadow-xl
            p-4
          "
        >
          <div><h3>Traits summary</h3></div>
          <div
            class="flex flex-col justify-start gap-2 self-rating"
            style="height: 50%"
          >
            <!-- <p
              class="
                p-1
                mt-1
                w-32
                rounded-xl
                font-bold
                shadow-md
                flex
                justify-center
                items-center
                text-sm 2xl:text-lg
              "
            >
              Traits Difference
            </p> -->
            <p class="ml-1 text-sm 2xl:text-lg mt-2">
              Highest rated:
              <span
                class="w-4 p-1 font-bold shadow-md rounded-xl"
                style="background-color: #d9d9d9"
              >{{ traitsSummary.highestRated }}</span>
            </p>
            <p class="ml-1 text-sm 2xl:text-lg mt-6">
              Lowest rated:
              <span
                class="w-4 p-1 font-bold shadow-md rounded-xl"
                style="background-color: #d9d9d9"
              >{{ traitsSummary.lowestRated }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import content from '../content.json'
import spiderChartTeam from './spiderChartTeam.vue'
import BarChart from './BarChart.vue'
import { maxColor, estimateColor } from '../chartConfig'

export default {
  props: {
    campaignId: {
      type: String,
      required: false,
      default: null
    },
    isTraitsSelected: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  async mounted () {
    const [data, ratingUsers] = await this.$api.getTeamRatingResultsForGraphs(
      this.campaignId
    )
    this.teamRatingUsers = ratingUsers
    this.teamSpiderChartData[0].data =
      data[1].teamInfo.averageTeamRatingformattedData
    this.teamSpiderChartData[1].data =
      data[1].teamInfo.averageCapacityformattedData
    this.Summary = data[1].teamInfo.diffData

    this.dataIndividual = data[1].individualInfo
    this.traitsDataExists =
      this.dataIndividual[Object.keys(this.dataIndividual)[0]]
        .selfRatingTraitsFormatted.length > 0

    this.cognitiveDataExists = this.dataIndividual[Object.keys(this.dataIndividual)[0]].selfRatingCognitiveMeasuresFormatted.length > 0

    this.numberOfGroups = Math.ceil(
      Object.keys(this.dataIndividual).length / 5
    )

    // if no cognitive ratings show only traits graphs
    if (!this.cognitiveDataExists) {
      this.$emit('cognitiveDataNotExists')
    }

    this.teamSpiderChartDataTraits[0].data = data[1].teamInfo.averageTeamRatingformattedDataTraits
    this.averageTraits = data[1].teamInfo.averageTeamRatingformattedDataTraits
    this.updateBarChartData()

    this.dataIsLoaded = true
    this.getTraitsSummary()
  },
  components: {
    spiderChartTeam,
    BarChart
  },
  data () {
    return {
      dataIsLoaded: false,
      content: content,
      names: {},
      bar: 0,
      traitsDataExists: false,
      cognitiveDataExists: true,
      dataIndividual: {},
      selectedAbility: 'Simple Attention',
      teamRatingBarChartData: {},
      capacityBarChartData: {},
      numberOfGroups: 0,
      teamRatingUsers: null,
      Summary: null,
      averageTraits: null,

      Abilities: [
        'Simple Attention',
        'Attention Control',
        'Short-term Memory',
        'Working Memory',
        'Impulse Control',
        'Creativity & Cognitive Flexibility',
        'Conceptualization',
        'Strategic Thinking'
      ],
      labelTraitsFull: [
        'Leadership',
        'Target Orientation',
        'Adaptability',
        'Teambuilding',
        'Communication',
        'Commitment',
        'Accountability',
        'Service Minded',
        'Independence',
        'Accuracy',
        'Initiative',
        'Cooperativeness',
        'Ambition',
        'Problem Solving',
        'Stress Resistance'
      ],
      labelsTraits: [
        'leadership',
        'target_orientation',
        'adaptability',
        'teambuilding',
        'communication',
        'commitment',
        'accountability',
        'service_minded',
        'independence',
        'accuracy',
        'initiative',
        'cooperativeness',
        'ambition',
        'problem_solving',
        'stress_resistance'],
      labels: [
        'simple_attention',
        'attention_control',
        'short_term_memory',
        'working_memory',
        'impulse_control',
        'creativity_cognitive_flexibility',
        'conceptualization',
        'strategic_thinking'
      ],
      teamSpiderChartData: [
        {
          name: 'Average rating',
          data: []
        },
        {
          name: 'Avarage capacity',
          data: []
        }
      ],
      teamSpiderChartDataTraits: [
        {
          name: 'Averate rating',
          data: []
        }
      ],
      traitsSummary: {
        highestRated: null,
        lowestRated: null
      }
    }
  },
  methods: {
    updateBarChartData () {
      const numberOfPeople = Object.keys(this.dataIndividual).length
      for (let i = 1; i <= this.numberOfGroups; i++) {
        this.names['names' + i] = []
        this.teamRatingBarChartData['teamRatingBarChartData' + i] = {
          label: 'Average rating',
          backgroundColor: maxColor,
          pointBackgroundColor: maxColor,
          borderWidth: 1,
          pointBorderColor: maxColor,
          data: []
        }

        this.capacityBarChartData['capacityBarChartData' + i] = {
          label: 'Capacity',
          backgroundColor: estimateColor,
          pointBackgroundColor: estimateColor,
          borderWidth: 1,
          pointBorderColor: estimateColor,
          data: []
        }

        const GROUP_SIZE = 5
        const GROUP_INDEX = i - 1
        const LAST_INDEX = numberOfPeople - 1

        for (let j = 0; j < GROUP_SIZE; j++) {
          const NAME_INDEX = j + GROUP_SIZE * GROUP_INDEX

          const currentName = Object.keys(this.dataIndividual)[NAME_INDEX]
          const currentCapValue = this.dataIndividual[currentName].capacity[
            this.Abilities.indexOf(this.selectedAbility)
          ]
          const currentRatingValue = this.dataIndividual[currentName]
            .teamRatingCognitiveMeasuresFormatted[
              this.Abilities.indexOf(this.selectedAbility)
            ]

          if (LAST_INDEX < NAME_INDEX) {
            break
          } else if (LAST_INDEX === NAME_INDEX) {
            this.names['names' + i].push(currentName)
            this.capacityBarChartData['capacityBarChartData' + i].data.push(
              currentCapValue
            )
            this.teamRatingBarChartData['teamRatingBarChartData' + i].data.push(
              currentRatingValue
            )
            break
          } else {
            this.capacityBarChartData['capacityBarChartData' + i].data.push(
              currentCapValue
            )
            this.teamRatingBarChartData['teamRatingBarChartData' + i].data.push(
              currentRatingValue
            )
            this.names['names' + i].push(currentName)
          }
        }
      }
    },
    getTraitsSummary () {
      const highestVal = Math.max.apply(null, this.averageTraits)
      const lowestVal = Math.min.apply(null, this.averageTraits)

      const highestIndex = this.averageTraits.indexOf(highestVal)
      const lowestIndex = this.averageTraits.indexOf(lowestVal)
      this.traitsSummary.highestRated = this.labelTraitsFull[highestIndex]
      this.traitsSummary.lowestRated = this.labelTraitsFull[lowestIndex]
    }
  },
  watch: {
    selectedAbility () {
      this.updateBarChartData()
    },
    bar () {
      if (this.bar === this.numberOfGroups) {
        this.bar = 0
      }
    }
  }
}
</script>

<style scoped>
.SummaryCard {
  height: 863px;
  width: 1700px;
  margin-top: 2em;
  border-radius: 20px;
  padding: 2em;
}

.SelectPerson {
  margin-right: 200px;
}

.PersonInfo {
  max-width: 500px;
}

.FirstColumn {
  max-height: 340px;
  min-height: 240px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.SecondColumn {
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}
.RadarChart {
  border-radius: 20px;
  width: 700px;
}
.BarChart {
  border-radius: 20px;
  width: 800px;
}

.Summary {
  border-radius: 20px;
  width: 700px;
  }
</style>
